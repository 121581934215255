<template>
  <div style="height: 100%;overflow: hidden;">
    <div class="box">
      <div class="main">
        <div class="search">
          <el-button type="primary" @click="AddUser">+ 新建成员</el-button>
          <div class="search_mid">
            <span>关键字：</span>
            <el-input v-model="Key" placeholder="请输入姓名"></el-input>
            <!-- <span>组织</span>
             <el-select v-model="id" placeholder="请选择" @change="OrganizationChange">
                        <el-option
                          v-for="(item,index) in organizationlist"
                          :key="index"
                          :label="item.Name"
                          :value="item.Id">
                        </el-option>
              </el-select> -->
          </div>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button type="primary" @click="Reset">重置</el-button>
        </div>
        <!-- 表格内容 -->
        <div class="primary">
          <el-table ref="multipleTable" :data="userList" height="600"
            style="width: 100%;height:600px;margin-bottom: 10px;" :header-cell-style="{
              color: '#333333',
              backgroundColor: 'rgb(240, 247, 253)',
            }" border>
            <el-table-column type="selection" width="40" align="center">
            </el-table-column>
            <el-table-column type="index" label="序号" min-width="60" align="center"></el-table-column>
            <el-table-column prop="OrganizationName" label="组织名称" align="center">
            </el-table-column>
            <el-table-column prop="LoginName" label="姓名" min-width="40" align="center">
            </el-table-column>
            <el-table-column label="性别" min-width="40" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.Sex == 1 ? '男' : '' }}</p>
                <p>{{ scope.row.Sex == 0 ? '女' : '' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" min-width="65" align="center">
            </el-table-column>
            <el-table-column prop="IdCardNo" label="身份证号" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="EMail" label="邮箱" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="WorkUnitName" label="所属医院" min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="DeptName" label="所属科室" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="TitleName" label="职称" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="RoleName" label="角色" min-width="80" align="center">
            </el-table-column>
            <el-table-column label="配置" min-width="80" align="center">
              <template slot-scope="scope">
                <el-button type="text" style="margin-right: 10px;font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="EditMember(scope.row)">编辑</el-button>
                <el-button type="text" style="font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="DelMember(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
            @pagination="UserGetPageList"></Pagination>
        </div>
      </div>
      <!-- 弹出框 -->
      <div class="dialog_add">
        <el-dialog :visible.sync="addMemberDialog" v-dialogDrag :before-close="Cancel">
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age" v-show="type == 1">新增成员</span>
            <span class="title-age" v-show="type == 2">修改成员</span>
          </div>
          <el-form label-width="100px" :model="AddUserFrom" ref="AddUserFrom" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="登录名:" prop="LoginName">
                  <el-input v-model="AddUserFrom.LoginName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="微信:">
                  <el-input v-model="AddUserFrom.WX"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="密码:" prop="PwdPlainText">
                  <el-input v-model="AddUserFrom.PwdPlainText"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱:">
                  <el-input v-model="AddUserFrom.EMail"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="姓名:" prop="Name">
                  <el-input v-model="AddUserFrom.Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职称:" required>
                  <el-select v-model="AddUserFrom.Title" filterable placeholder="请选择">
                    <el-option v-for="(item, index) in titleList" :key="index" :label="item.Name" :value="item.Code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="手机号:" :prop="type == 2 ? '' : 'PhoneNumber'">
                  <el-input v-model="AddUserFrom.PhoneNumber"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="医院:" required>
                  <el-select v-model="AddUserFrom.WorkUnitId" filterable remote :remote-method="remoteMethod"
                    v-el-select-loadmore="loadmore" placeholder="请选择">
                    <el-option v-for="(item, index) in hospital" :key="index" :label="item.Name" :value="item.Id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="身份证:">
                  <el-input v-model="AddUserFrom.IdCardNo" @change="change"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="科室:" required>
                  <el-select v-model="AddUserFrom.DeptId" filterable placeholder="请选择">
                    <el-option v-for="(item, index) in Department" :key="index" :label="item.Name" :value="item.Code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="性别:" required>
                  <el-radio-group v-model="AddUserFrom.Sex">
                    <el-radio label="1">男</el-radio>
                    <el-radio label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="角色:" required>
                  <el-select v-model="AddUserFrom.RoleId" filterable placeholder="请选择">
                    <el-option v-for="(item, index) in role" :key="index" :label="item.Name" :value="item.Id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="20" v-if="admin=='00000000-0000-0000-0000-000000000000'"> -->
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="组织:" required>
                  <el-select v-model="AddUserFrom.OrganizationId" filterable placeholder="请选择" @change="change1">
                    <el-option v-for="(item, index) in organization" :key="index" :label="item.Name" :value="item.Id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="btn">
            <button @click="Cancel">取消</button>
            <button type="primary" @click="AddPersonnel">保存</button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import administrators from "../../api/administrators"
import PatientCenter from "../../api/PatientCenter"
import ProjectManagement from "../../api/ProjectManagement"
import Pagination from "../../components/Pagination/index.vue"
import { validatePhone, validateregId, validatePassword } from "../../utils/verification"
import { local } from "../../utils/storage"
export default {
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      admin: local.get("currentOrganizationId"),
      rules: {
        LoginName: [
          { required: true, message: '登录名不能为空', trigger: 'blur' }
        ],
        Name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        PhoneNumber: [
          { validator: validatePhone, trigger: 'blur', required: true }
        ],
        IdCardNo: [
          { validator: validateregId, trigger: 'blur', required: true }
        ],
        PwdPlainText: [
          { validator: validatePassword, trigger: 'blur', required: true }
        ],
      },
      type: 1,
      organization: [],
      titleList: [],
      hospital: [],
      Department: [],
      DeptId: [],
      role: [],
      addMemberDialog: false,
      total: 0,
      Key: '',
      userList: [],
      listQuery: {
        page: 1,
        rows: 20,
      },
      selectPage: 1,
      selectRows: 20,
      remoteName: '',
      AddUserFrom: {
        OrganizationId: "",
        LoginName: "",
        Name: "",
        IdCardNo: "",
        Sex: "",
        Title: "",
        PhoneNumber: "",
        WX: "",
        EMail: "",
        PwdPlainText: "",
        DeptId: "",
        WorkUnitId: '',
        RoleId: "",
      },
      id: "",
      //  organizationlist:[]
    }
  },
  components: {
    Pagination
  },
  created() {
    if (this.admin != '00000000-0000-0000-0000-000000000000') {
      this.AddUserFrom.OrganizationId = this.admin;
      const role = {
        OrganizationId: this.AddUserFrom.OrganizationId
      }
      console.log(role, "---");
      //科室
      ProjectManagement.RoleListData(role).then(res => {//角色
        if (res.data.Status == 1) {
          this.role = res.data.Data
          console.log('role', this.role)
        } else {
          alert(res.data.Message)
        }
        console.log(res, "角色");
      })
    }
    this.UserGetPageList('')
    // this.NoPagingOrganization()
  },
  methods: {
    change1(e) {
      this.role = [];
      this.AddUserFrom.RoleId = '';
      const role = {
        OrganizationId: e
      }
      //科室
      ProjectManagement.RoleListData(role).then(res => {//角色
        if (res.data.Status == 1) {
          this.role = res.data.Data
          console.log('role', this.role)
        } else {
          alert(res.data.Message)
        }
        console.log(res, "角色");
      })
    },
    Cancel() {
      this.organization = [];
      // this.role = [];
      // this.AddUserFrom.OrganizationId = '';
      // this.AddUserFrom.RoleId = '';
      // this.AddUserFrom.IdCardNo = '';
      this.AddUserFrom = {
        OrganizationId: "",
        LoginName: "",
        Name: "",
        IdCardNo: "",
        Sex: "",
        Title: "",
        PhoneNumber: "",
        WX: "",
        EMail: "",
        PwdPlainText: "",
        DeptId: "",
        WorkUnitId: '',
        RoleId: "",
      },
        this.$refs.AddUserFrom.resetFields();
      this.addMemberDialog = false;
    },
    change(e) {
      this.AddUserFrom.Sex = this.IdCard(e, 2)
    },
    IdCard(IdCard, type) {
      if (type === 1) {
        //获取出生日期
        let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
        return birthday
      }
      if (type === 2) {
        //获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
          return "1"
        } else {
          return "0"
        }
      }
      if (type === 3) {
        //获取年龄
        var ageDate = new Date()
        var month = ageDate.getMonth() + 1
        var day = ageDate.getDate()
        var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
          age++
        }
        if (age <= 0) {
          age = 1
        }
        return age
      }
    },
    //组织
    NoPagingOrganization() {
      const parameter = {
        Key: "",
      }
      administrators.NoPagingOrganizationData(parameter).then(res => {
        console.log(res, "组织");
        if (res.data.Status == 1) {
          this.organization = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    Reset() {
      this.Key = '';
      this.UserGetPageList()
    },
    query() {
      this.UserGetPageList(this.Key)
    },
    //编辑成员
    EditMember(row) {
      this.selectPage = 1;
      this.selectRows = 20;
      this.hospital = [];
      //添加单位
      const parameter = {
        Key: row.WorkUnitName,
        page: this.selectPage,
        rows: this.selectRows,
      }
      ProjectManagement.GetPageListCompanyData(parameter).then(res => {
        if (res.data.Status == 1) {
          const result = this.hospital.some(item => {
            return item.Id == row.WorkUnitId;
          });
          if (!result) {
            res.data.Data.rows.forEach(item => {
              this.hospital.unshift(item)
            })
          }
        } else {
          alert(res.data.Message);
        }
      })
      this.role = [];
      this.AddUserFrom.RoleId = '';
      const role = {
        OrganizationId: row.OrganizationId
      }
      //科室
      ProjectManagement.RoleListData(role).then(res => {//角色
        if (res.data.Status == 1) {
          this.role = res.data.Data
          console.log('res+++++++++++++', res.data)
        } else {
          alert(res.data.Message)
        }
      })

      this.type = 2
      this.AddUserFrom = JSON.parse(JSON.stringify(row))
      this.addMemberDialog = true
      this.BaseDataBig()
      console.log(row);
    },
    //删除成员
    DelMember(row) {
      const parameter = []
      parameter.push(row.Id)
      this.$confirm('此操作将永久删除该成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        administrators.DeleteUserData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.userList = this.userList.filter(item => {
              return item.Id != row.Id
            })
          } else {
            alert(res.data.Message)
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      })
      console.log(row);
    },
    //管理员成员列表
    UserGetPageList() {
      const parameter = {
        // OrganizationId:name,
        Key: this.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
      }
      administrators.UserGetPageListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.total = res.data.Data.total
          this.userList = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    //远程搜索单位医
    remoteMethod(name) {
      this.remoteName = name;
      this.selectPage = 1;
      this.hospital = [];
      this.Company(name)
    },
    loadmore() {
      this.selectPage++;
      this.Company(this.remoteName);
    },
    //打开新增弹框
    AddUser() {
      //初始化医院
      this.remoteName = '';
      this.selectPage = 1;
      this.hospital = [];
      this.type = 1;
      this.addMemberDialog = true
      this.BaseDataBig()
    },
    //单位列表接口
    Company(Name) {
      const parameter = {
        Key: Name,
        page: this.selectPage,
        rows: this.selectRows
      }
      //之前getlist无分页
      //  ProjectManagement.GetListCompanyData(parameter).then(res=>{
      //       if(res.data.Status==1){
      //           this.hospital=res.data.Data;
      //       }else{
      //           alert(res.data.Message)
      //       }
      //       console.log(res,"医院");
      //  });
      ProjectManagement.GetPageListCompanyData(parameter).then(res => {
        if (res.data.Status == 1) {
          res.data.Data.rows.forEach(item => {
            this.hospital.push(item)
          })
        } else {
          alert(res.data.Message);
        }
      })
    },
    BaseDataBig() {
      const Department = "KS"//科室
      PatientCenter.BaseDataBigData(Department).then(res => {
        if (res.data.Status == 1) {
          this.Department = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "科室");
      })
      const titleList = "ZC"//科室
      PatientCenter.BaseDataBigData(titleList).then(res => {
        if (res.data.Status == 1) {
          this.titleList = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "职称");
      })
      this.Company('')
      this.NoPagingOrganization()
      //  this.Company('')
    },
    //确定新增修改弹框
    AddPersonnel() {
      if (this.type == 1) {
        administrators.AddUserData(this.AddUserFrom).then(res => {
          if (res.data.Status == 1) {
            this.userList.push(res.data.Data)
            this.addMemberDialog = false
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.AddUserFrom = {
              OrganizationId: "",
              LoginName: "",
              Name: "",
              IdCardNo: "",
              Sex: "",
              Title: "",
              PhoneNumber: "",
              WX: "",
              EMail: "",
              PwdPlainText: "",
              DeptId: "",
              WorkUnitId: '',
              RoleId: "",
            }
          } else {
            alert(res.data.Message)
          }
        })
      } else {
        administrators.EditUserData(this.AddUserFrom).then(res => {
          if (res.data.Status == 1) {
            this.userList = this.userList.map(item => {
              if (item.Id == this.AddUserFrom.Id) {
                item = this.AddUserFrom
              }
              return item
            })
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.addMemberDialog = false
          } else {
            alert(res.data.Message)
          }
          console.log(res);
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import "../../styles/mixin.scss";

::v-deep .el-dialog__footer {
  text-align: center;
}

.box {
  overflow: hidden;
  // position: absolute;
  //top: 0;
  width: 100%;

  ::v-deep .el-breadcrumb__inner a,
  ::v-deep .el-breadcrumb__inner.is-link {
    font-size: 15px;
    color: #00f6ff;
    cursor: pointer;
  }

  ::v-deep .el-breadcrumb__item:last-child::v-deep .el-breadcrumb__inner:hover {
    color: #00f6ff;
    cursor: pointer;
  }

  .main {
    //border: 1px solid #00f6ff;
    border-top: 0;
    height: 80%;
    margin: 0 20px;

    .search {
      display: flex;
      align-items: center;
      padding: 10px;
      width: 98%;
      margin: 0 auto;
      margin-top: 20px;
      height: 25px;
      line-height: 25px;

      //color: #00f6ff;
      .search_mid {
        margin: 0 20px;

        span {
          display: inline-block;
          margin: 10px 5px;
          color: #666666;
          @include add-size($font_size_16);
        }

        .el-input {
          width: 150px;
          height: 40px;
          display: inline-block;

          ::v-deep .el-input__inner {
            @include add-size($font_size_16);
            height: 40px;
          }
        }

      }
    }

    .primary {

      /* //element-ui table的去除右侧滚动条的样式 */
      ::-webkit-scrollbar {
        width: 10px;
        height: 1px;
      }

      /* // 滚动条的滑块 */
      ::-webkit-scrollbar-thumb {
        background-color: rgb(24, 144, 254);
        border-radius: 10px;
      }

      height: 80%;
      width: 98%;
      overflow: hidden;
      border-color: #031c65;
      margin: 20px 20px;

      ::v-deep .view {
        color: #fff;
      }

      ::v-deep .related {
        color: #fff;
      }

      ::v-deep .el-table {
        // border-color: #031c65
        border: 0;
        color: #00f6ff;
        background-color: transparent;
      }

      ::v-deep .el-table tr {
        border: 0;
        color: #606266;
      }

      ::v-deep .el-table th {
        padding: 8px 0;
      }

      ::v-deep .el-table--enable-row-transition .el-table__body td,
      .el-table .cell,
      .el-table__body {
        color: #606266 !important;

        .el-button {
          background-color: transparent;
          border: 0;
        }
      }

      .block {
        position: fixed;
        bottom: 15px;

        .left {
          margin-left: 20px;
          height: 32px;
          line-height: 32px;
          float: left;

          span {
            font-weight: normal;
            //  color: #07c5dc;
            color: #fff;
          }

          i {
            font-style: normal;
          }
        }

        .el-pagination {
          float: left;
        }
      }

      .el-table {
        ::v-deeptd {
          padding: 5px 0;

          ::v-deep .el-table-column--selection .cell {
            padding-left: 0;
          }
        }

        ::v-deep .cell {
          padding-left: 5px;

          .el-button+.el-button {
            margin-left: 0;
          }

          .el-button--mini {
            padding: 5px 5px;
          }
        }
      }
    }
  }
}

.dialog_add {
  ::v-deep .el-dialog {
    width: 1200px;
    border-radius: 10px;

    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;

      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }

        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }

      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }

    .body {
      height: 430px;

      .body-left {
        float: left;

        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }

            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
          }
        }
      }

      .body-right {
        float: left;

        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }

            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }

            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }

            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;

              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }

            .block {

              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }

            .el-radio__label {
              @include add-size($font_size_16);
            }
          }
        }
      }
    }

    .btn {
      margin-bottom: 14px;
      text-align: center;

      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }

      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}

.systemsetting ::v-deep .el-dialog {
  border-radius: 10px;

  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;

    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 5px;
      }

      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }

  .btn {
    margin-top: 52px;
    text-align: center;

    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }

    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}

::v-deep .el-select {
  .el-icon-circle-close {
    background-color: #606266;
  }
}

.primary ::v-deep .el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}

.primary ::v-deep .el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
</style>
